import Vuex, {Store} from 'vuex';
import Vue from 'vue';
import {MapStylesState} from "../../base_map_styles_dialog/store/modules/BaseMapStylesDialogModule";
import MapStylesDialogModule from "../../base_map_styles_dialog/store/modules/BaseMapStylesDialogModule";
import RecordingMapModule, { RecordingMapState } from "./modules/RecordingMapModule";
import PlanMapModule, { PlanMapState } from "./modules/PlanMapModule";

Vue.use(Vuex)

export interface RootState {
  mapStyles: MapStylesState
  planMap: PlanMapState
}

const store: Store<RootState> = new Vuex.Store({
  modules: {
    mapStyles: new MapStylesDialogModule(),
    planMap: new PlanMapModule(),
  }
})

export default store;
