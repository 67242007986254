// Create a vue app
import Vue from 'vue';
import RecordedTrails from "./RecordedTrails.vue";


if (document.getElementById('recorded-trails') != null) {
  new Vue({
    render: (h) => h(RecordedTrails),
  }).$mount('#recorded-trails');
}
