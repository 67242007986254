// Create a vue app
import Vue from 'vue';
import PlannedTrails from "./PlannedTrails.vue";


if (document.getElementById('planned-trails') != null) {
  new Vue({
    render: (h) => h(PlannedTrails),
  }).$mount('#planned-trails');
}
