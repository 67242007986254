import Vue from "vue";
import RecordingMap from "./RecordingMap.vue"
import store from "./store/store";



if (document.querySelector('#recording_map')) {
  window.recording_map = new Vue({
    store,
    render: (createElement) => {
      return createElement(RecordingMap)
    }
  }).$mount('#recording_map');
}
