import Vue from 'vue';
import Vuex from 'vuex';
import UserRepository from '../../repositories/UserRepository';
import LoginApp from './LoginApp.vue';

Vue.use(Vuex);
const store = new Vuex.Store();
store.registerModule('UserRepository', UserRepository);

if (document.querySelector('#login')) {
  window.vue = new Vue({
    store,
    render: (h) => h(LoginApp),
  }).$mount('#login');
}
