import { render, staticRenderFns } from "./EditRecordingDetails.vue?vue&type=template&id=66c810ae&scoped=true&"
import script from "./EditRecordingDetails.vue?vue&type=script&lang=ts&"
export * from "./EditRecordingDetails.vue?vue&type=script&lang=ts&"
import style0 from "./EditRecordingDetails.vue?vue&type=style&index=0&id=66c810ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c810ae",
  null
  
)

export default component.exports