import {gql} from "graphql-request";

export const SearchPtv2PlannedTrailsQuery = gql`
  query SearchPtv2PlannedTrailsQuery(
    $search: String!,
    $startDate: ISO8601DateTime,
    $endDate: ISO8601DateTime,
    $page: Int,
    $pageSize: Int
  ) {
    ptv2PlannedTrailsTextSearch(
      string: $search,
      startDate: $startDate,
      endDate: $endDate,
      page: $page,
      pageSize: $pageSize
    ) {
      id
      title
      createdAt
      updatedAt
      clientId
      thumbnailUrl
      distanceMeters
      shareableUrl
      elevationGainMeters
      errors {
        errors
        fieldName
      }
      calculatedDurationMinutes
    }
  }
`

export const GetAllPtv2PlannedTrails = gql`
  query GetAllPtv2PlannedTrails($page: Int) {
    ptv2PlannedTrails(page: $page) {
      id
      title
      createdAt
      updatedAt
      thumbnailUrl
      distanceMeters
      elevationGainMeters
      plannedToHikeOn
      errors {
        errors
        fieldName
      }
      calculatedDurationMinutes
    }
  }
`

