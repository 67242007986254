import Vue from "vue";
import RecordingCropApp from "./RecordingCropApp.vue";

if (document.querySelector('#recording_crop_app')) {
  window.recording_crop_app = new Vue({
    render: (createElement) => {
      return createElement(RecordingCropApp)
    }
  }).$mount('#recording_crop_app');
}
