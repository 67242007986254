import firebase from '../src/js/firebase';
import axiosClient from '../src/utils/helpers/axios_client';

const UPLOAD_FOLDER = 'UPLOAD_FOLDER';
const PICTURE_EXTENSION = '.jpg';
const PROFILE_PICTURE_FOLDER = 'PROFILE_PICTURE_FOLDER';
const USERS = 'USERS';
const PUBLIC = 'PUBLIC';
const FAV_TRAILS = 'FAV_TRAILS';
const COMPLETED_TRAILS = 'COMPLETED_TRAILS';

const state = () => ({
  admin: false,
  user: null,
  loadingUser: true,
  settings: { measurementUnits: 'imperial' },
  favTrails: [],
  favTrailNames: [],
  loadingFavTrails: true,
  completedTrails: [],
  loadingCompletedTrails: true,
  profilePicUrl: null,
  uploadingProfilePicture: false,
  coverPicUrl: null,
  uploadingCoverPicture: false,
  bio: null,
  link: null,
  username: null,
  viewedTrails: [],
  imperial: false,
});

const actions = {
  async logOutUser() {
    await axiosClient.get('/logout');
    await firebase.auth().signOut();
  },
  async bindAuth({ commit }) {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await commit({ type: 'SET_USER', user });
        firebase.database().ref().child('USERS').child(user.uid)
          .child('last_login')
          .set(Date.now())
          .then();
        commit({ type: 'SET_LOADING_USER', boolean: false });
      } else {
        await commit({ type: 'SET_USER', user: {} });
        commit({ type: 'SET_LOADING_USER', boolean: false });
      }
    });
  },
  async toggleFavTrail(context, args) {
    context.commit('SET_LOADING_FAV_TRAILS', true);
    const shouldRemoveTrail = context.state.favTrails.filter((itm) => itm.name === args.name).length > 0;
    const trailNames = context.state.favTrails.filter((t) => {
      if (shouldRemoveTrail) {
        return t.name !== args.name;
      }
      return true;
    }).map((trailItm) => trailItm.name);
    if (!shouldRemoveTrail && !trailNames.includes(args.name)) {
      trailNames.push(args.name);
    }
    await firebase
      .database()
      .ref()
      .child(USERS)
      .child(args.userId)
      .child(PUBLIC)
      .child(FAV_TRAILS)
      .set(trailNames);
    context.commit('SET_LOADING_FAV_TRAILS', false);
  },
  async removeCompletedTrail(context, args) {
    const newArray = context.state.completedTrails.filter((itm) => itm.name !== args);
    const userId = context.state.user.uid;
    const trailNames = newArray.map((trailItm) => trailItm.name);
    await firebase
      .database()
      .ref()
      .child(USERS)
      .child(userId)
      .child(PUBLIC)
      .child(COMPLETED_TRAILS)
      .set(trailNames);
  },
  async getCoverPicUrl({ commit, getters }) {
    const reference = firebase
      .storage()
      .ref()
      .child(UPLOAD_FOLDER)
      .child(PROFILE_PICTURE_FOLDER)
      .child(getters.coverPicturePath);

    reference.getDownloadURL().then(
      (image) => {
        commit('SET_COVER_PIC_URL', image);
      },
      (error) => {},
    );
  },
  async bindProfile(context) {
    await firebase.database().ref().child('USERS').child(context.state.user.uid)
      .child('PUBLIC')
      .child('bio')
      .on('value', (snapshot) => {
        if (snapshot.exists()) { context.state.bio = snapshot.val(); }
      });
    await firebase.database().ref().child('USERS').child(context.state.user.uid)
      .child('PUBLIC')
      .child('link')
      .on('value', (snapshot) => {
        if (snapshot.exists()) { context.state.link = snapshot.val(); }
      });
    await firebase.database().ref().child('USERS').child(context.state.user.uid)
      .child('PUBLIC')
      .child('username')
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          context.state.username = snapshot.val();
        } else if (firebase.auth().currentUser != null && firebase.auth().currentUser.displayName != null) {
          context.state.username = firebase.auth().currentUser.displayName;
        } else {
          context.state.username = 'A.HiiKER';
        }
      });
  },
  async uploadCoverPic({ commit, dispatch, getters }, file) {
    commit('SET_UPLOADING_COVER_PICTURE', true);
    const ref = firebase
      .storage()
      .ref()
      .child(UPLOAD_FOLDER)
      .child(PROFILE_PICTURE_FOLDER)
      .child(getters.coverPicturePath);
    ref.put(file).then(async (snapshot) => {
      // is there a way to get the downloadURL from this snapshot?
      await dispatch('getCoverPicUrl');
      commit('SET_UPLOADING_COVER_PICTURE', false);
    });
  },

  trailIsFaved(context, args) {
    return context.state.favTrails.filter((trail) => trail.name === args).length > 0;
  },

  async viewedTrail(context, args) {
    if (context.state.user && context.state.user.uid) {
      await firebase.database().ref().child('USERS').child(context.state.user.uid)
        .child('VIEWED_TRAILS')
        .child(args.makeFirebaseSafe())
        .set(args);
    }
  },

  async bindViewedTrails(context) {
    if (context.state.user && context.state.user.uid) {
      firebase.database().ref().child('USERS').child(context.state.user.uid)
        .child('VIEWED_TRAILS')
        .on('value', async (snapshot) => {
          if (snapshot.exists()) {
            const val = snapshot.val();
            const trailNames = Object.keys(val);
            context.commit('SET_VIEWED_TRAILS', trailNames);
          }
        });
    }
  },
};

const getters = {
  userName(state) {
    return state.username ? state.name : state.user.displayName;
  },
  profilePicturePath(state) {
    return state.user.uid + PICTURE_EXTENSION;
  },
  coverPicturePath(state) {
    return `${state.user.uid}-cover-pic${PICTURE_EXTENSION}`;
  },
};

const mutations = {
  SET_LOADING_USER(state, payload) {
    state.loadingUser = payload.boolean;
  },
  SET_ADMIN(state, payload) {
    state.admin = payload;
  },
  SET_UPLOADING_PROFILE_PICTURE(state, payload) {
    state.uploadingProfilePicture = payload;
  },
  SET_UPLOADING_COVER_PICTURE(state, payload) {
    state.uploadingCoverPicture = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_LOADING_COMPLETED_TRAILS(state, payload) {
    state.loadingCompletedTrails = payload;
  },
  SET_LOADING_FAV_TRAILS(state, payload) {
    state.loadingFavTrails = payload;
  },
  SET_USER(state, payload) {
    state.user = payload.user;
  },
  SET_FAV_TRAILS(state, payload) {
    state.favTrails = payload;
  },
  SET_FAV_TRAIL_NAMES(state, payload) {
    state.favTrailNames = payload;
  },
  SET_COMPLETED_TRAILS(state, payload) {
    state.completedTrails = payload;
  },
  SET_PROFILE_PIC_URL(state, payload) {
    state.profilePicUrl = payload;
  },
  SET_COVER_PIC_URL(state, payload) {
    state.coverPicUrl = payload;
  },
  SET_VIEWED_TRAILS(state, payload) {
    state.viewedTrails = payload;
  },
  SET_IMPERIAL(state, payload) {
    state.imperial = payload;
  },
};

export default {
  actions,
  getters,
  mutations,
  state,
};
