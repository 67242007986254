<template>
  <div>
    <div ref="firebaseAuthMount"></div>
  </div>
</template>

<script>
import firebase from '../js/firebase';

export default {
  data() {
    return {
      firebase,
    };
  },
  methods: {
    mountFirebaseAuthUI() {
      this.firebase.hiikerFirebaseAuthUIStart(this.$refs.firebaseAuthMount);
    },
  },
  mounted() {
    this.mountFirebaseAuthUI();
  },
};
</script>
