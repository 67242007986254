import Vue from "vue";
import PlanMap from "./PlanMap.vue";
import store from "./store/store";

if (document.querySelector('#plan_map')) {
  window.plan_map = new Vue({
    store,
    render: (createElement) => {
      return createElement(PlanMap)
    }
  }).$mount('#plan_map');
}
