// Import necessary styles and libraries
import Vue from 'vue';
import ImportRecordingDialog from "./ImportRecordingDialog.vue";

// Check if the specific element exists in the DOM
if (document.querySelector('#vue-import-recording-dialog')) {
  // Create a new Vue instance and mount it to the element
  window.vue = new Vue({
    render: (createElement) => {
      return createElement(ImportRecordingDialog)
    },
  }).$mount('#vue-import-recording-dialog');
}
