const $copyModal = $('#copyModal'); // TODO: remove jQuery dependency
const $openCopyModalButtons = document.getElementsByClassName('openCopyModalButton');
const $copyConfirmation = document.getElementById('copyURLConfirmation');
const $shareTrailInput = document.getElementById('trailShareInput');
const $copyURLInputGroup = document.getElementById('copyURLInputGroup');
const $closeButton = document.getElementById('close');

if ($copyModal.length > 0 && $openCopyModalButtons.length > 0) {
  $closeButton.addEventListener('click', () => {
    $copyModal.modal('hide');
  });

  // Ensure $copyURLInputGroup has a single click listener
  $copyURLInputGroup.addEventListener('click', () => {
    alert('click');
    $shareTrailInput.select();
    $shareTrailInput.setSelectionRange(0, 99999); // For mobile devices
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText($shareTrailInput.value).then(() => {
        $copyConfirmation.style.display = 'inline';
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      console.warn('Clipboard API not supported');
    }
  });
  Array.from($openCopyModalButtons).forEach(($button) => {
    $button.addEventListener('click', (event) => {
      const copyUrl = event.currentTarget.dataset.copyUrl;
      $shareTrailInput.value = copyUrl;
      $copyConfirmation.style.display = 'none';
      $copyModal.modal('show');
    });
  });
}
