import Vue from "vue";
import {ActionTree, Module, Store} from "vuex";
import store, {RootState} from "../store";


export class RecordingMapState {

}

const state = Vue.observable(new RecordingMapState(null, null));

const actions: ActionTree<RecordingMapState, RootState> = {

}


const mutations = {

}

const getters = {

};

const modules = {

}

export default class RecordedMapModule implements Module<RecordingMapState, RootState> {
  namespaced = true
  state = state
  actions = actions
  mutations = mutations
  getters = getters
  modules = modules
}
