<template>
  <div class="extra">
    <div class="container login">
      <div class="row">
        <div class="col-12">
          <div class="card py-3">
            <FirebaseAuth />
            <div style="display: block; margin: auto">
              <p class="text-center mt-2">Or</p>
              <img src="../../assets/img/apple-id-sign-in-with_2x.webp"
                   style="margin: auto; display: flex; max-width: 220px; cursor: pointer;"
                   @click="signInWithAppleClicked"
                   alt="sign in with apple">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import FirebaseAuth from '../components/FirebaseAuth.vue';
import firebase from '../js/firebase';

export default {
  components: {
    FirebaseAuth,
  },
  methods: {
    signInWithAppleClicked() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let redirectUrl;
      if (this.redirectUrl) {
        redirectUrl = this.redirectUrl;
      } else {
        redirectUrl = urlParams.get('page');
      }
      firebase.hiikerSignInWithApple(redirectUrl);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.UserRepository.user,
    }),
  },
};
</script>
